<template>
    <div>

        <div class="row grayBg">
            <div class="big-box">
                <div class="col-one" ref="imgCutterBox">
                    <div v-if="params.isModal">
                        <div style="width: 50%; margin: 180px auto auto auto">
                            <div class="btn btn-primary btn-block" @click="pluginExe('handleOpen')">
                                {{ params.label }}
                            </div>
                            <div class="btn btn-secondary btn-block" @click="forIe9">
                                测试
                            </div>
                        </div>
                    </div>
                    <ImgCutter v-if="refresh" ref="imgCutterModal" :crossOrigin="params.crossOrigin" :label="params.label"
                        :rate="params.rate" :index="params.index" :isModal="params.isModal"
                        :showChooseBtn="params.showChooseBtn" :lockScroll="params.lockScroll" :boxWidth="params.boxWidth"
                        :boxHeight="params.boxHeight" :cutWidth="params.cutWidth" :cutHeight="params.cutHeight"
                        :sizeChange="params.sizeChange" :moveAble="params.moveAble" :imgMove="params.imgMove"
                        :tool="params.tool" :fileType="params.fileType" :originalGraph="params.originalGraph"
                        :previewMode="params.previewMode" :toolBoxOverflow="params.toolBoxOverflow"
                        :WatermarkText="params.WatermarkText" :WatermarkTextFont="params.WatermarkTextFont"
                        :WatermarkTextColor="params.WatermarkTextColor" :WatermarkTextX="params.WatermarkTextX"
                        :WatermarkTextY="params.WatermarkTextY" :smallToUpload="params.smallToUpload"
                        :saveCutPosition="params.saveCutPosition" :scaleAble="params.scaleAble" :accept="params.accept"
                        :DoNotDisplayCopyright="false" toolBgc="params.toolBgc" @onChooseImg="onChooseImg"
                        @onClearAll="onClearAll" @onPrintImg="onPrintImg" @error="catchError" @cutDown="cutDown"
                        @david="david">
                        <template #open></template>
                        <template #choose v-if="params.isModal == false"> </template>
                        <template #cancel v-if="params.isModal == false"> </template>
                        <template #confirm v-if="params.isModal == false"> </template>
                    </ImgCutter>
                </div>
                <div class="col-two">
                    <div :style="{ background: lbgcolor }" class="imgCutDownBox obj-select" id="left-pic"
                        @click="selectimg('left')">
                        <span class="trash-ico" @click="ClearThis('left')"> <i class="fas fa-trash"></i></span>
                        <span class="color-ico"><el-color-picker class="color" v-model="lbgcolor" :show-alpha="true"
                                :color-format="colorFormat" prefix-icon="custom-icon"></el-color-picker></span>
                        <img alt="背景" :src="bg" class="mask" id="image1">
                        <img :src="imgSrc_left" v-if="!!imgSrc_left" alt="" class="boximg" id="image2" />
                        <span v-else class="imgCutDownTips">{{ $t('block3.title8') }}</span>

                    </div>
                </div>
                <div class="col-three">
                    <div :style="{ background: rbgcolor }" class="imgCutDownBox" id="right-pic" @click="selectimg('right')">
                        <span class="trash-ico" @click="ClearThis('right')"> <i class="fas fa-trash"></i></span>
                        <span class="color-ico"><el-color-picker class="color" v-model="rbgcolor" :show-alpha="true"
                                :color-format="colorFormat" prefix-icon="custom-icon"></el-color-picker></span>
                        <img alt="背景" :src="bg" class="mask" id="image3">
                        <img :src="imgSrc_right" v-if="!!imgSrc_right" alt="" class="boximg" id="image4" />
                        <span v-else class="imgCutDownTips">{{ $t('block3.title10') }}</span>
                    </div>
                </div>
                <div style="display:; display:none;">
                    <canvas id="canvas"></canvas>
                    <img alt="左效果图" src="" class="mask" id="image5" ref="image5">
                    <img alt="左原图" src="" class="mask" id="image6" ref="image6">
                    <img alt="右效果图" src="" class="mask" id="image7" ref="image7">
                    <img alt="右原图" src="" class="mask" id="image8" ref="image8">

                </div>
            </div>
            <div class="text-center" style="width:100%;display: none;">
                <a :class="'btn btn-light' + (imgSrc_left ? '' : ' disabled')" :disabled="!imgSrc_left" :href="imgSrc_left"
                    :download="downloadName || 'image.jpg'" role="button">{{ $t('block3.title7') }}</a>
            </div>
            <div class="col-md-12">
                <div class="card" style="display: none;">
                    <div class="card-header">{{ $t('config.title') }}</div>
                    <div class="card-body">
                        <form autocomplete="off">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="label">{{ $t('config.label') }}（label）：</label>
                                        <input @input="setData($event)" name="label" type="text" class="form-control"
                                            v-model="params.label" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="boxWidth">{{ $t('config.boxWidth') }}（boxWidth）：</label>
                                        <input @input="setData($event)" :readonly="params.isModal == false" name="boxWidth"
                                            type="text" class="form-control" v-model="params.boxWidth" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="boxHeight">{{ $t('config.boxHeight') }}（boxHeight）：</label>
                                        <input @input="setData($event)" :readonly="params.isModal == false" name="boxHeight"
                                            type="text" class="form-control" v-model="params.boxHeight" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="cutWidth">{{ $t('config.cutWidth') }}（cutWidth）：</label>
                                        <input @input="setData($event)" name="cutWidth" type="text" class="form-control"
                                            v-model="params.cutWidth" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="cutHeight">{{ $t('config.cutHeight') }}（cutHeight）：</label>
                                        <input @input="setData($event)" name="cutHeight" type="text" class="form-control"
                                            v-model="params.cutHeight" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="rate">{{ $t('config.rate') }}（rate）：</label>
                                        <input @input="setData($event)" name="rate" type="text" class="form-control"
                                            placeholder="eg：1:1" v-model="params.rate" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="toolBgc">{{ $t('config.toolBgc') }}（toolBgc）：</label>
                                        <input @input="setData($event)" name="toolBgc" type="text" class="form-control"
                                            v-model="params.toolBgc" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="crossOriginHeader">
                                            {{ $t('config.crossOriginHeader') }}（crossOriginHeader）：</label>
                                        <input @input="setData($event)" name="crossOriginHeader" type="text"
                                            class="form-control" v-model="params.crossOriginHeader" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="crossOrigin">{{ $t('config.crossOrigin') }}（crossOrigin）：</label>
                                        <select @change="setData($event)" name="crossOrigin" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="isModal">{{ $t('config.isModal') }}（isModal）：</label>
                                        <select @change="setData($event)" name="isModal" class="custom-select">
                                            <option value="true">YES</option>
                                            <option value="false" selected>NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="showChooseBtn">
                                            {{ $t('config.showChooseBtn') }}（showChooseBtn）：
                                        </label>
                                        <select @change="setData($event)" name="showChooseBtn" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="lockScroll">{{ $t('config.lockScroll') }}（lockScroll）：</label>
                                        <select @change="setData($event)" name="lockScroll" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="sizeChange">{{ $t('config.sizeChange') }}（sizeChange）：</label>
                                        <select @change="setData($event)" name="sizeChange" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="moveAble">{{ $t('config.moveAble') }}（moveAble）：</label>
                                        <select @change="setData($event)" name="moveAble" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="tool">{{ $t('config.tool') }}（tool）：</label>
                                        <select @change="setData($event)" name="tool" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="originalGraph">{{ $t('config.originalGraph') }}（originalGraph）：</label>
                                        <select @change="setData($event)" name="originalGraph" class="custom-select">
                                            <option value="true">YES</option>
                                            <option value="false" selected>NO</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="saveCutPosition">{{ $t('config.saveCutPosition') }}（ saveCutPosition
                                            ）：</label>
                                        <select @change="setData($event)" name="saveCutPosition" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="scaleAble">{{ $t('config.scaleAble') }}（ scaleAble ）：</label>
                                        <select @change="setData($event)" name="scaleAble" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="imgMove">{{ $t('config.imgMove') }}（ imgMove ）：</label>
                                        <select @change="setData($event)" name="imgMove" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="fileType">{{ $t('config.fileType') }}（ fileType ）：</label>
                                        <input @input="setData($event)" name="fileType" type="text" class="form-control"
                                            v-model="params.fileType" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="index">{{ $t('config.index') }}（index）：</label>
                                        <input @input="setData($event)" name="index" type="text" class="form-control"
                                            v-model="params.index" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="smallToUpload">
                                            {{ $t('config.smallToUpload') }} （smallToUpload ）：</label>
                                        <select @change="setData($event)" name="smallToUpload" class="custom-select">
                                            <option value="true">YES</option>
                                            <option value="false" selected>NO</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="toolBoxOverflow">
                                            {{ $t('config.toolBoxOverflow') }}（toolBoxOverflow）：
                                        </label>
                                        <select @change="setData($event)" name="toolBoxOverflow" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="previewMode">{{ $t('config.previewMode') }}（previewMode）：</label>
                                        <select @change="setData($event)" name="previewMode" class="custom-select">
                                            <option value="true" selected>YES</option>
                                            <option value="false">NO</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="WatermarkText">{{ $t('config.WatermarkText') }}（WatermarkText）：</label>
                                        <input @input="setData($event)" name="WatermarkText" type="text"
                                            class="form-control" v-model="params.WatermarkText" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="WatermarkTextFont">{{ $t('config.WatermarkTextFont')
                                        }}（WatermarkTextFont）：</label>
                                        <input @input="setData($event)" name="WatermarkTextFont" type="text"
                                            class="form-control" v-model="params.WatermarkTextFont" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="WatermarkTextColor">{{ $t('config.WatermarkTextColor')
                                        }}（WatermarkTextColor）：</label>
                                        <input @input="setData($event)" name="WatermarkTextColor" type="text"
                                            class="form-control" v-model="params.WatermarkTextColor" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="WatermarkTextX">{{ $t('config.WatermarkTextX')
                                        }}（WatermarkTextX）：</label>
                                        <input @input="setData($event)" name="WatermarkTextX" type="text"
                                            class="form-control" v-model="params.WatermarkTextX" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="WatermarkTextY">{{ $t('config.WatermarkTextY')
                                        }}（WatermarkTextY）：</label>
                                        <input @input="setData($event)" name="WatermarkTextY" type="text"
                                            class="form-control" v-model="params.WatermarkTextY" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="quality">{{ $t('config.quality') }}（quality）：</label>
                                        <input @input="setData($event)" name="WatermarkTextFont" type="text"
                                            class="form-control" v-model="params.quality" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group custom-control">
                                        <label for="accept">{{ $t('config.accept') }}（accept）：</label>
                                        <input @input="setData($event)" name="accept" type="text" class="form-control"
                                            v-model="params.accept" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;display: none;">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">HTML</div>
                            <div class="card-body">
                                <pre>
                                        <code>{{ code1 }}</code>
                                      </pre>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">Javascript</div>
                            <div class="card-body" style="height: 623px">
                                <pre>
                                        <code>{{ code2 }}</code>
                                      </pre>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">提交信息</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group" id="name-box">
                                <label for="name">姓名</label>
                                <input type="text" name="name" id="name" class="form-control" placeholder="請輸入姓名" />
                                <div class="invalid-feedback">請輸入姓名！</div>
                            </div>
                            <div class="form-group" id="email-box">
                                <label for="email">郵件</label>
                                <input type="text" name="email" id="email" class="form-control" placeholder="請輸入郵件" />
                                <div class="invalid-feedback">請輸入郵件！</div>
                            </div>
                            <div class="form-group">
                                <label for="phone">電話(可Whatsapp確認訂單)</label>
                                <input type="text" name="phone" id="phone" class="form-control"
                                    placeholder="請輸入電話或Whatsapp" />
                            </div>
                            <div class="form-group">
                                <label for="size">尺寸</label>
                                <select name="size" class="form-control" id="size">
                                    <option value="S">S</option>
                                    <option value="M">M</option>
                                    <option value="L">L</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="note">備註</label>
                                <input type="text" name="note" class="form-control" id="note" placeholder="請輸入備註" />
                            </div>
                            <div class="form-group">
                                <button type="button" class="btn btn-success btn-block" @click="send()">
                                    確認
                                </button>
                            </div>
                            <div class="form-group">
                                <button type="button" class="btn btn-light btn-block" :disabled="params.isModal === true"
                                    @click="pluginExe('clearAll')">
                                    重置
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>

        <div class="modal" v-if="showModal">
            <div class="modal-content">
                <div class="alert alert-success" id="msgbox">
                    {{ msg }}
                </div>
                <button @click="closeModal('clearAll')" class="btn btn-success btn-block" id="msgbutton"
                    :disabled="disableButton">关闭</button>
            </div>
        </div>
    </div>
</template>

<script>
import config from '../package.json';
import ImgCutter from './components/MobileImgCutter.vue';
// import html2canvas from 'html2canvas';
import { ref } from 'vue';
import axios from 'axios';
export default {
    name: 'App',
    components: {
        ImgCutter,
    },
    data() {
        return {
            name: '',
            imageDataUrl: '',
            drawMaskedImage: '',
            version: '',
            author: '',
            description: '',
            bg: 'bg.png',
            logo1: 'logo1.png',
            logo2: 'logo2.png',
            gitee: 'https://gitee.com/GLUESTICK/vue-img-cutter',
            download: 'https://github.com/acccccccb/vue-img-cutter/archive/master.zip',
            cutImgSrc: 'https://phpcrm-oss.oss-cn-chengdu.aliyuncs.com/weixinpay.png',
            homepage: 'https://www.ihtmlcss.com',
            docs: 'https://github.com/acccccccb/vue-img-cutter/blob/master/README.md',
            npm: 'https://www.npmjs.com/package/vue-img-cutter',
            cutImgWidth: 250,
            cutImgHeight: 250,
            imgSrc_right: null,
            imgSrc_left: null,
            downloadName: '',
            refresh: true,
            isForIe9: false,
            loadImg: false,
            lefteffect: '',
            leftsource: '',
            righteffect: '',
            rightsource: '',
            onPrintImgTimmer: null,
            showModal: false,
            select_object: 'left',
            msg: "",
            lbgcolor: 'white', // 绑定选中的颜色值
            rbgcolor: 'white', // 绑定选中的颜色值
            colorFormat: 'hex', // 颜色格式


            params: {
                label: this.$t('block3.title9'),
                fileType: 'png',
                crossOrigin: true,
                crossOriginHeader: '*',
                rate: '',
                toolBgc: 'none',
                isModal: false,
                showChooseBtn: true,
                saveCutPosition: true,
                scaleAble: true,
                lockScroll: true,
                toolBoxOverflow: true,
                quality: 1,
                boxWidth: 500,
                boxHeight: 458,
                cutWidth: 249,
                cutHeight: 362,
                sizeChange: true,
                david: '',
                moveAble: false,
                imgMove: true,
                tool: true,
                originalGraph: false,
                WatermarkText: '',
                WatermarkTextFont: '12px Sans-serif',
                WatermarkTextColor: '#00ff00',
                WatermarkTextX: 0.95,
                WatermarkTextY: 0.95,
                smallToUpload: true,
                previewMode: true,
                index: '',
                disableButton: false,
                accept: 'image/gif, image/jpeg ,image/png',
            },

            code1: '',
            code2:
                '\n' +
                '// ' +
                this.$t('block3.title4') +
                '\n' +
                'forIe9:() => {\n' +
                '   this.$refs.imgCutterModal.handleOpen({\n' +
                '       name:1.png,\n' +
                '       src:http://imgurl.com/1.png,\n' +
                '   });\n' +
                '}',
        };
    },
    created() {
        console.log('created');
        this.name = config.name;
        this.version = config.version;
        this.author = config.author;
        this.description = config.description;
    },
    mounted() {
        // 监听touchmove事件
        //document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
        console.log('mounted');
        let $imgCutterBox = this.$refs.imgCutterBox;
        this.params.boxWidth = $imgCutterBox.offsetWidth - 30;
        this.createCode();
    },
    methods: {
        handleTouchMove(event) {
            // 阻止默认行为
            event.preventDefault();
        },
        cutDown: function (res) {

            console.log('无图', res);

            if (this.select_object == 'left') {

                this.imgSrc_left = res.dataURL;
                this.leftsource = res.david;
                const foregroundImage = document.getElementById('image1');
                const canvas = document.getElementById('canvas');
                canvas.width = 349;
                canvas.height = 508;
                const ctx = canvas.getContext('2d');
                // 创建两个Image对象来加载图片
                const image1 = new Image();
                const image2 = new Image();
                // 设置图片源
                image1.src = foregroundImage.src;
                image2.src = res.dataURL;
                // 等待图片加载完成
                image1.onload = () => {
                    // 绘制第一张图片
                    ctx.drawImage(image2, 0, 0, 349, 508);
                    // 绘制第二张图片
                    ctx.drawImage(image1, 0, 0);
                    // 可以在此处添加其他绘制操作，如文本等

                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = this.lbgcolor; // 设置填充颜色
                    ctx.fillRect(0, 0, 349, 508); // 填充整个画布

                    // 保存合并后的图像
                    const mergedImage = canvas.toDataURL('image/png');
                    //console.log(mergedImage);
                    const image5 = document.getElementById('image5');
                    image5.src = mergedImage;
                    this.lefteffect = mergedImage;

                    const image6 = document.getElementById('image6');
                    image6.src = this.leftsource;

                };
            }
            else {
                this.imgSrc_left = this.lefteffect;
                this.imgSrc_right = res.dataURL;
                this.rightsource = res.david;
                const foregroundImage = document.getElementById('image3');
                const canvas = document.getElementById('canvas');
                canvas.width = 349;
                canvas.height = 508;
                const ctx = canvas.getContext('2d');
                // 创建两个Image对象来加载图片
                const image3 = new Image();
                const image4 = new Image();
                // 设置图片源
                image3.src = foregroundImage.src;
                image4.src = res.dataURL;
                // 等待图片加载完成
                image3.onload = () => {
                    // 绘制第一张图片
                    ctx.drawImage(image4, 0, 0, 349, 508);
                    // 绘制第二张图片
                    ctx.drawImage(image3, 0, 0);
                    // 可以在此处添加其他绘制操作，如文本等
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = this.rbgcolor; // 设置填充颜色
                    ctx.fillRect(0, 0, 349, 508); // 填充整个画布

                    // 保存合并后的图像
                    const mergedImage = canvas.toDataURL('image/png');
                    const image7 = document.getElementById('image7');
                    image7.src = mergedImage;
                    this.righteffect = mergedImage;
                    const image8 = document.getElementById('image8');
                    image8.src = this.rightsource;
                };
            }
            this.downloadName = res.fileName;
        },
        forIe9: function () {
            this.$refs.imgCutterModal.handleOpen({
                name: this.cutImgSrc,
                src: this.cutImgSrc,
                // width: this.cutImgWidth,
                // height: this.cutImgHeight,
            });
        },
        doRefresh: function () {
            this.refresh = false;
            this.$nextTick(() => {
                this.refresh = true;
            });
        },
        onClearAll: function () {
            // 清空事件
            this.loadImg = false;
            this.imgSrc_right = '';
            this.imgSrc_left = '';

        },
        onPrintImg: function (res) {
            // 预览图片
            if (this.select_object == 'left') {
                this.imgSrc_left = res.dataURL;
                this.imgSrc_right = this.righteffect;
            }
            else {
                this.imgSrc_left = this.lefteffect;
                this.imgSrc_right = res.dataURL;
            }
        },
        onChooseImg: function (res) {
            // 选择图片事件
            if (res) {
                this.loadImg = true;
            }
        },
        createCode: function () {
            this.code1 =
                '\n' +
                '<ImgCutter\n' +
                '   ref="imgCutterModal"\n' +
                '   label="' +
                this.params.label +
                '"\n' +
                '   fileType="' +
                this.params.fileType +
                '"\n' +
                '   :crossOrigin="' +
                this.params.crossOrigin +
                '"\n' +
                '   crossOriginHeader="' +
                this.params.crossOriginHeader +
                '"\n' +
                '   rate="' +
                this.params.rate +
                '"\n' +
                '   toolBgc="' +
                this.params.toolBgc +
                '"\n' +
                '   :isModal="' +
                this.params.isModal +
                '"\n' +
                '   :showChooseBtn="' +
                this.params.showChooseBtn +
                '"\n' +
                '   :lockScroll="' +
                this.params.lockScroll +
                '"\n' +
                '   :boxWidth="' +
                this.params.boxWidth +
                '"\n' +
                '   :boxHeight="' +
                this.params.boxHeight +
                '"\n' +
                '   :cutWidth="' +
                this.params.cutWidth +
                '"\n' +
                '   :cutHeight="' +
                this.params.cutHeight +
                '"\n' +
                '   :sizeChange="' +
                this.params.sizeChange +
                '"\n' +

                '   :david="' +
                this.params.david +
                '"\n' +

                '   :moveAble="' +
                this.params.moveAble +
                '"\n' +
                '   :imgMove="' +
                this.params.imgMove +
                '"\n' +
                '   :originalGraph="' +
                this.params.originalGraph +
                '"\n' +
                '   WatermarkText="' +
                this.params.WatermarkText +
                '"\n' +
                '   WatermarkTextFont="' +
                this.params.WatermarkTextFont +
                '"\n' +
                '   WatermarkTextColor="' +
                this.params.WatermarkTextColor +
                '"\n' +
                '   :WatermarkTextX="' +
                this.params.WatermarkTextX +
                '"\n' +
                '   :WatermarkTextY="' +
                this.params.WatermarkTextY +
                '"\n' +
                '   :smallToUpload="' +
                this.params.smallToUpload +
                '"\n' +
                '   :saveCutPosition="' +
                this.params.saveCutPosition +
                '"\n' +
                '   :scaleAble="' +
                this.params.scaleAble +
                '"\n' +
                '   :previewMode="' +
                this.params.previewMode +
                '"\n' +
                '   :quality="' +
                this.params.quality +
                '"\n' +
                '   :accept="' +
                this.params.accept +
                '"\n' +
                '   :toolBoxOverflow="' +
                this.params.toolBoxOverflow +
                '"\n' +
                '   :index="' +
                this.params.index +
                '"\n' +
                '   @cutDown="cutDown">\n' +
                '       <div class="btn btn-primary" #open>' +
                this.params.label +
                '</div>\n' +
                '</ImgCutter>';
        },
        setData: function ($event) {
            function isNumber(val) {
                let regPos = /^\d+(\.\d+)?$/; //非负浮点数
                let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
                if (regPos.test(val) || regNeg.test(val)) {
                    return true;
                } else {
                    return false;
                }
            }

            let value = $event.target.value;
            if (value == 'true') {
                value = true;
            }
            if (value == 'false') {
                value = false;
            }
            if (isNumber(value) === true) {
                value = parseInt(value);
            }
            this.params[$event.target.name] = value;
            this.doRefresh();
            this.createCode();
        },
        catchInput(e) {
            let val = e.target.value;
            let key = e.target.name;
            this[key] = val;
        },
        catchError(res) {
            console.log(res);
            window.alert(res.msg);
        },
        pluginExe: function (functionName) {
            this.$refs.imgCutterModal[functionName]();
        },

        send: function () {

            const name = document.getElementById('name');
            const phone = document.getElementById('phone');
            const email = document.getElementById('email');
            const note = document.getElementById('note');
            const sizeSelect = document.querySelector('#size');
            if (name.value == "") {
                const invalidFeedback = document.querySelector('#name-box .invalid-feedback');
                invalidFeedback.style.display = 'block';
                return false;
            }
            if (email.value == "") {
                const invalidFeedback = document.querySelector('#email-box .invalid-feedback');
                invalidFeedback.style.display = 'block';
                return false;
            }

            const image5Src = this.$refs.image5 && this.$refs.image5.src.includes('data:image') ? this.$refs.image5.src : null;
            const image6Src = this.$refs.image6 && this.$refs.image6.src.includes('data:image') ? this.$refs.image6.src : null;
            const image7Src = this.$refs.image7 && this.$refs.image7.src.includes('data:image') ? this.$refs.image7.src : null;
            const image8Src = this.$refs.image8 && this.$refs.image8.src.includes('data:image') ? this.$refs.image8.src : null;

            if (image5Src === null || image6Src === null || image7Src === null || image8Src === null) {
                alert('未提交效果圖');
                return false;
            } 



            //console.log(image5.currentSrc);

            const base64Data5 = image5Src;
            const base64Data6 = image6Src;
            const base64Data7 = image7Src;
            const base64Data8 = image8Src;

            //console.log(image5.currentSrc);

            const byteCharacters5 = base64Data5.split(',')[1];
            const byteCharacters6 = base64Data6.split(',')[1];
            const byteCharacters7 = base64Data7.split(',')[1];
            const byteCharacters8 = base64Data8.split(',')[1];

            const byteArrays5 = [];
            const byteArrays6 = [];
            const byteArrays7 = [];
            const byteArrays8 = [];

            for (let i = 0; i < byteCharacters5.length; i++) {
                byteArrays5.push(byteCharacters5.charCodeAt(i));
            }
            for (let i = 0; i < byteCharacters6.length; i++) {
                byteArrays6.push(byteCharacters6.charCodeAt(i));
            }
            for (let i = 0; i < byteCharacters7.length; i++) {
                byteArrays7.push(byteCharacters7.charCodeAt(i));
            }
            for (let i = 0; i < byteCharacters8.length; i++) {
                byteArrays8.push(byteCharacters8.charCodeAt(i));
            }

            const byteArray5 = new Uint8Array(byteArrays5);
            const byteArray6 = new Uint8Array(byteArrays6);
            const byteArray7 = new Uint8Array(byteArrays7);
            const byteArray8 = new Uint8Array(byteArrays8);

            const blob5 = new Blob([byteArray5], { type: 'image/jpeg' });
            const blob6 = new Blob([byteArray6], { type: 'image/jpeg' });
            const blob7 = new Blob([byteArray7], { type: 'image/jpeg' });
            const blob8 = new Blob([byteArray8], { type: 'image/jpeg' });



            const postData = new FormData();
            postData.append('name', name.value);
            postData.append('phone', phone.value);
            postData.append('email', email.value);
            postData.append('note', note.value);
            postData.append('size', sizeSelect.value);

            postData.append('image5', blob5, 'image5.jpg');
            postData.append('image6', blob6, 'image6.jpg');
            postData.append('image7', blob7, 'image7.jpg');
            postData.append('image8', blob8, 'image8.jpg');



            this.showModal = true;
            this.msg = "請稍候! 正在向伺服器提交資料.";
            this.disableButton = true;

            axios.post('http://shinguard.mtsportswear.com.hk/upload.php', postData)
                .then(response => {
                    if (response.data === '邮件发送成功') {
                        console.log('邮件发送成功');
                        this.msg = "您的訂單已經提交成功,我們將盡快與您聯絡.";
                        this.disableButton = false;
                        // 在这里执行其他操作，比如显示成功提示给用户
                    } else {
                        console.log(response.data);
                        this.msg = response.data;
                        this.disableButton = false;
                        // 在这里处理其他情况
                    }
                })
                .catch(error => {
                    // 处理错误
                    console.log(error, ref);
                });
        },
        closeModal: function (functionName) {
            this.showModal = false;
            this.$refs.imgCutterModal[functionName]();
        },
        ClearThis: function (e) {
            console.log(e);
            if (e == 'left') {
                this.left_pic = '0',
                    this.left = '',
                    this.leftsource = '',
                    this.imgSrc_left = ''
                document.getElementById('image5').src = '';
                document.getElementById('image6').src = '';
            }

            else {
                this.rightsource = '',
                    this.imgSrc_right = ''
                document.getElementById('image7').src = '';
                document.getElementById('image8').src = '';
            }
        },
        selectimg: function (e) {
            console.log(e);
            if (e == 'left') {
                this.select_object = 'left';
                document.getElementById('left-pic').classList.add('obj-select');
                document.getElementById('right-pic').classList.remove('obj-select');

            }

            else {
                this.select_object = 'right';
                document.getElementById('right-pic').classList.add('obj-select');
                document.getElementById('left-pic').classList.remove('obj-select');
            }
        }


    },
    beforeUnmount() {
        // 在组件销毁前移除事件监听
        document.removeEventListener('touchmove', this.handleTouchMove);
    }
};
</script>
<style scoped>
.bg {
    padding-top: 50px;
}

.grayBg {
    background: #000;
}


.imgCutDownBox {
    overflow: hidden;

    text-align: center;
    width: 315px;
    height: 458px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    border: 2px solid #fbf9f5;
    border-radius: 10px;
}

.imgCutDownBox .mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 11;
    width: 100%;
}

.imgCutDownBox .boximg {

    max-height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    height: 100%;
}

.imgCutDownBox .trash-ico {
    position: absolute;
    margin-top: 10px;
    cursor: pointer;
    right: 10px;
    z-index: 999;
    color: #9edbb8;
}

.imgCutDownBox .color-ico {
    position: absolute;
    margin-top: 5px;
    cursor: pointer;
    right: 30px;
    z-index: 999;
    color: #9edbb8;

}


.obj-select {
    background-color: transparent;
    border: 2px solid #9edbb8;
    border-radius: 10px;
}

.imgCutDownTips {
    font-size: 0.8em;
    line-height: 458px;
    color: #c0c4cc;
}

.alipay {
    width: 200px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #333;
    display: inline-block;
    margin-right: 30px;
}

.title {
    font-size: 0.8em;
    line-height: 50px;
    color: #c0c4cc;
    text-align: center;
}

.alipay img {
    width: 100%;
}

.jumbotron {
    border-radius: 0;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%) !important;
    padding: 4rem 0 0 0;
    color: #fff;
    text-align: center;
}

svg {
    shape-rendering: auto;
    width: 100%;
    height: 100px;
}

svg:not(:root) {
    overflow: hidden;
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

.card {
    width: 95%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    width: 82%;
}


@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

.big-box {
    width: 95%;
    margin: auto;
    background: rgb(255, 255, 255);
    border-radius: .25rem;
}

.col-one {
    width: 95%;
    position: relative;
    margin: auto;
    height: 550px;

}

.col-two {
    width: 95%;
    position: relative;
    margin: auto;
    height: 550px;
}

.col-three {
    width: 95%;
    position: relative;
    margin: auto;
    height: 550px;
}

.el-color-picker {
    position: absolute !important;
}

.el-color-picker__trigger {
    border: none !important;
}

.color-ico .el-color-picker .el-color-picker__trigger {
    width: 39px;
    height: 28px;
    border: none !important;
}

.el-color-picker__color-inner {
    color: rgb(78, 3, 3)(73, 18, 18);
}

.logo-left {
    position: absolute;
    top: 50px;
    left: 20px;
}


.logo-left .logo-img {
    width: 150px;
    height: auto;
    display: block;
    margin: auto;
}

.logo-right {
    position: absolute;
    top: 50px;
    right: 20px;

}

.logo-right .logo-img {
    height: auto;
    display: block;
    margin: auto;
    width: 60px;
}
</style>
