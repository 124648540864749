import { createApp } from 'vue';
import App from './App.vue';
import Mobile from './Mobile.vue';

import i18n from './i18n/index.js';
import { useI18n } from 'vue-i18n';
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.css'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

const isMobile = window.innerWidth <= 768; // 判断设备宽度是否小于等于768px

const app = createApp(isMobile ? Mobile : App);

//const app = createApp(App);
app.use(i18n);
app.use(ElementPlus);
app.config.globalProperties.$t = useI18n;
app.config.globalProperties.i18n = i18n;
app.config.globalProperties.$http = axios
app.mount('#app');