export default {
    'introduce.docs': '文档',
    'introduce.gitee': 'Gitee',
    'introduce.github': 'Github',
    'introduce.npm': 'Npm',
    'introduce.download': '下载',
    'introduce.homepage': '作者主页',
    'banner.download': '下载压缩包',
    'banner.description':
        '简单易用的vue图片裁剪插件，支持移动图像，裁剪图片，放大缩小图片，上下左右移动，固定比例，固定尺寸，远程图片裁剪',
    'block1.title1': '使用简单',
    'block1.title2': '方便快捷',
    'block1.title3': '只需以下几个步骤',
    'block1.title4': '安装',
    'block1.title5': '引入',
    'block1.title6': '注册组件',
    'block1.title7': '编写代码',
    'block1.title8': '处理返回结果',
    'block2.title1': '在线配置',
    'block2.title2': '预览并生成代码',
    'block2.title3': '使用此工具配置并生成代码',
    // 预览区域
    'block3.title1': '远程图片地址',
    'block3.title2': '選擇本地圖片',
    'block3.title3': '裁剪远程图片',
    'block3.title4': '裁剪远程图片',
    'block3.title5': '確認',
    'block3.title6': '重置',
    'block3.title7': '下载图片',
    'block3.title8': '左側預覽區域',
    'block3.title9': '選擇本地圖片',
    'block3.title10': '右側預覽區域',
    // 捐赠
    'donation.title1': '捐赠',
    'donation.title2': '捐赠以支持作者',
    'donation.title3': '请我喝杯咖啡吧 :)',
    // 设置项
    'config.title': '配置工具',
    'config.label': '按钮文字',
    'config.boxWidth': '裁剪组件宽度',
    'config.boxHeight': '裁剪组件高度',
    'config.cutWidth': '选择框宽度',
    'config.cutHeight': '选择框高度',
    'config.rate': '宽高比',
    'config.toolBgc': '工具栏背景色',
    'config.crossOriginHeader': '跨域header头',
    'config.crossOrigin': '是否跨域',
    'config.isModal': '是否为弹窗',
    'config.showChooseBtn': '显示选择图片按钮',
    'config.lockScroll': '锁定滚动',
    'config.sizeChange': '允许改变大小',
    'config.moveAble': '允许拖动裁剪框',
    'config.tool': '显示工具栏',
    'config.originalGraph': '原图裁剪',
    'config.saveCutPosition': '保存裁剪框位置',
    'config.scaleAble': '允许缩放图片',
    'config.imgMove': '允许移动图片',
    'config.fileType': '返回文件类型',
    'config.index': '随结果一起返回，用于区分是那个裁剪组件返回的',
    'config.smallToUpload': '如果选择的图片尺寸小于裁剪框尺寸，直接返回结果。 sizeChange必须为false',
    'config.toolBoxOverflow': '允许裁剪框超出图片边界',
    'config.previewMode': '实时返回裁剪结果',
    'config.WatermarkText': '水印文字',
    'config.WatermarkTextFont': '水印文字样式',
    'config.WatermarkTextColor': '水印文字颜色',
    'config.WatermarkTextX': '水印x轴位置',
    'config.WatermarkTextY': '水印y轴位置',
    'config.quality': '图片质量',
    'config.accept': '可选文件类型',
};
